@use '../../../../assets/sass/variables.scss' as *;
@use '../../assets/sass/colors.scss' as *;
@use "ag-grid-community/styles" as ag;



.ovex-DealerContracts {

  .ovex-DealerContractsFilter, .ovex-DealerContractsButtonBar {
    margin-bottom: $ovex-horizontal-space;
  }

  &-column {
    // .ovex-DealerContracts-column

    &-working {
      // .ovex-DealerContracts-column-working
      background-color: $ovex-annual-target-column-working-bg-color;
      --ag-header-cell-hover-background-color: #{$ovex-annual-target-column-working-bg-color};
    }
    &-approved {
      // .ovex-DealerContracts-column-approved
      background-color: $ovex-annual-target-column-approved-bg-color;
      --ag-header-cell-hover-background-color: #{$ovex-annual-target-column-approved-bg-color};
    }
    &-statistics {
      // .ovex-DealerContracts-column-statistics
      background-color: $ovex-annual-target-column-statistics-bg-color;
      --ag-header-cell-hover-background-color: #{$ovex-annual-target-column-statistics-bg-color};
      color: $ovex-annual-target-column-statistics-color;
    }
  }

}
