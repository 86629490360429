
.ovex-DealerContractsButtonBar {

  &-btn-edit { // .ovex-DealerContractsButtonBar-btn-edit
    &.uu5-common-disabled[title] {
      pointer-events: auto;
    }
  }

}
