@use '../../../../../assets/sass/variables.scss' as *;

.ovex-forms-TextArea {

  .uu5-forms-label {
    font-size: $ovex-form-input-label-font-size;
  }

  .uu5-forms-message {
    font-size: $ovex-form-input-message-font-size;
    text-align: $ovex-form-input-message-text-align;
  }

  &:not(.uu5-forms-input-read-only) .uu5-forms-input-form-item {
    // editable input
    background: $ovex-form-input-background-color-editable !important;

    &.uu5-common-bg-style-underline:focus {
      border-bottom-width: $ovex-form-input-underline-width-active;
    }
  }
  &.uu5-forms-input-read-only .uu5-forms-input-form-item {
    // read only input
    background: $ovex-form-input-background-color-readonly;
  }

  .uu5-common-bg-style-underline:not(.uu5-forms-input-error) {
    border-color: $ovex-form-input-underline-color;
  }
}