@use '../../../../../../assets/sass/variables.scss' as *;

.AGGridTooltip {
  position: absolute;
  min-height: 1em;
}

.AGGridTooltip.ag-tooltip-hiding {
  opacity: 0;
}

.AGGridTooltip-hide {
  visibility: hidden;
}
