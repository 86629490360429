@use '../../../../../../assets/sass/variables.scss' as *;
@use '../../../../../../assets/sass/colours.scss' as *;


.ovex-pg-PriceGuaranteeCreationModalForm {

    //ovex-pg-PriceGuaranteeCreationModalForm-assigned-pg
    &-assigned-pg {
      background-color: $ovex-colour-gray-d8 !important;
      opacity: 0.5;
  }

}
