
@media print {

  .ovex-at-ContractPreview {
    border: unset;
    padding: unset;
    width: unset;
    margin-right: unset;
    white-space: pre-wrap;
    font-size: 11pt;

    > div:not(:last-child) {
      margin-bottom: 10mm;
    }

    &-contractHeader { // .ovex-at-ContractPreview-contractHeader
      text-align: center;

      &-title, // .ovex-at-ContractPreview-contractHeader-title
      &-subject { // .ovex-at-ContractPreview-contractHeader-subject
        font-weight: bold;
        font-size: 16pt;
      }

      &-validFromText { // .ovex-at-ContractPreview-contractHeader-validFromText
        margin-top: 6mm;
        font-size: 10pt;
      }
    }

    &-suppDeal { // .ovex-at-ContractPreview-suppDeal
      display: flex;

      > .ovex-at-ContractPreview-company {
        width: 50%;
      }
    }

    &-company { // .ovex-at-ContractPreview-company

      &-header { // .ovex-at-ContractPreview-company-header
        font-weight: bold;
      }

      &-address { // .ovex-at-ContractPreview-company-address
        margin-top: 3mm;
        margin-left: 6mm;
        font-size: 10pt;
      }

      &-number { // .ovex-at-ContractPreview-company-number
        margin-left: 6mm;
        font-size: 10pt;
      }
    }

    &-textBlock { // .ovex-at-ContractPreview-textBlock

      &-header { // .ovex-at-ContractPreview-textBlock-header
        font-weight: bold;
      }

      &-text { // .ovex-at-ContractPreview-textBlock-text
        margin-top: 3mm;
        margin-left: 6mm;
        font-size: 10pt;
      }

    }

    &-tableBlock { // .ovex-at-ContractPreview-tableBlock

      &-header { // .ovex-at-ContractPreview-tableBlock-header
        font-weight: bold;
      }

      &-table { // .ovex-at-ContractPreview-tableBlock-table
        white-space: normal; // fix ag-grid - *pre-wrap* from parent ident some rows
        margin-top: 3mm;

        .ovex-ag-grid .ag-row-last {
          border-bottom-style: none;
        }
      }

    }

  }

}