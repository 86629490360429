@use '../../../../../assets/sass/variables.scss' as *;


.ovex-DealerPeriodAnnualTargetSalesTable {

  .ag-row-group {
    &.ag-row-level-0, &.ag-row-level-1 {
      font-weight: bold;
    }
  }

  & &-cell-period { // .ovex-DealerPeriodAnnualTargetSalesTable-cell-period
    text-align: right;
    padding-right: $ovex-ag-grid-cell-padding-header-menu;
  }

  & &-cell-numeric { // .ovex-DealerPeriodAnnualTargetSalesTable-cell-numeric
    text-align: right;
    padding-right: $ovex-ag-grid-cell-padding-header-menu;

    &--editable { // .ovex-DealerPeriodAnnualTargetSalesTable-cell-numeric--editable
      &:before {
        content: "\F3EB";
        position: absolute;
        left: 8px;
      }
    }

    &--modified { // .ovex-DealerPeriodAnnualTargetSalesTable-cell-numeric--modified
      background: $radial-gradient-modified;
    }

  }
}

