
.ovex-AlertContent {
  display: flex;
  align-items: center;

  &-icon { // .ovex-AlertContent-icon
    font-size: 20px;
  }

  &-content { // .ovex-AlertContent-content
    margin-left: 8px;

    &-message { // .ovex-AlertContent-content-message
      font-weight: bold;
    }

    .uu5-bricks-ul {
      margin-top: 8px;
      margin-bottom: 0;
      font-size: 12px;
    }
  }
}