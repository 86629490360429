@use '../../../../assets/sass/variables.scss' as *;

.ovex-Modal {
  margin-top: $ovex-navbar-height;

  .uu5-bricks-modal-dialog {
    background: $ovex-app-background-colour;
  }
  .uu5-bricks-modal-body {
    background: $ovex-app-background-colour;
  }

  .uu5-forms-controls, .ovex-ButtonBar--formControls {
    margin-top: 24px;
  }
}