@use '../../../../assets/sass/variables.scss' as *;
@use '../../assets/sass/colors.scss' as *;


.ovex-DealerAnnualTarget {

  & &-select-contract { // .ovex-DealerAnnualTarget-select-contract
    margin-top: 0;
    margin-bottom: 32px;
  }

  & &-statistic-info { // .ovex-DealerAnnualTarget-statistic-info
    display: flex;
    align-items: center;
    height: $ovex-form-input-height;
    font-size: 14px;
    color: #999999;

    &-icon { // .ovex-DealerAnnualTarget-statistic-info-icon
      fill: #999999;
      margin-right: 8px;
    }
  }

  &-table { // .ovex-DealerAnnualTarget-table
    margin-top: 24px;
  }

  &-column {
    // .ovex-DealerAnnualTarget-column

    &-working {
      // .ovex-DealerAnnualTarget-column-working
      background-color: $ovex-annual-target-column-working-bg-color;
      --ag-header-cell-hover-background-color: #{$ovex-annual-target-column-working-bg-color};
    }
    &-approved {
      // .ovex-DealerAnnualTarget-column-approved
      background-color: $ovex-annual-target-column-approved-bg-color;
      --ag-header-cell-hover-background-color: #{$ovex-annual-target-column-approved-bg-color};
    }
    &-statistics {
      // .ovex-DealerAnnualTarget-column-statistics
      background-color: $ovex-annual-target-column-statistics-bg-color;
      --ag-header-cell-hover-background-color: #{$ovex-annual-target-column-statistics-bg-color};
      color: $ovex-annual-target-column-statistics-color;
    }
  }

}