@use '../../../../../assets/sass/variables.scss' as *;

.ovex-forms-switch-selector {

  .uu5-forms-label {
    font-size: $ovex-form-input-label-font-size;
  }

  .uu5-forms-message {
    font-size: $ovex-form-input-message-font-size;
    text-align: $ovex-form-input-message-text-align;
  }

  &.color-schema-green {

    .uu5-forms-input-form-item {
      color: $ovex-form-input-color;
    }

    &:not(.uu5-forms-input-read-only) .uu5-bricks-switch-selector {
      // editable input
      background: $ovex-form-input-background-color-editable;

      &.uu5-common-bg-style-underline:focus {
        border-bottom-width: $ovex-form-input-underline-width-active;
      }
    }

    .uu5-bricks-switch-selector {
      border-color: $ovex-form-input-underline-color;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

}
