
.ovex-ag-AGDatePicker {
  font-size: inherit;

  .uu5-forms-text-input {
    .uu5-forms-input-form-item.uu5-common-bg-style-underline {
      border-bottom-width: 0;
    }
    .uu5-bricks-icon {
      font-size: 16px;
    }
  }
}
