
.ovex-form-Periods {

  .uu5-forms-input-wrapper {
    .ovex-forms-number, .ovex-forms-checkbox {
      margin: 0;
    }
  }

}
