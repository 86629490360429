
.ovex-NegotiationHeaderForm {

  .coefficient-label {
    margin-top: 20px;
    font-size: 14px;
  }

  .roc-coefficient {
    display: flex;
    margin-bottom: 10px;


    .uu5-bricks-column-spacing:last-child {
      padding-right: 0;
    }

    .uu5-forms-input {
      margin-top: 0;
      margin-bottom: auto;
    }
  }

  .uu5-bricks-row {

    .uu5-bricks-column {
      .uu5-forms-daterangepicker:last-child {
        margin-bottom: 0;
      }

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}