@use '../../../assets/sass/variables.scss' as *;

.ovex-app {
  background-color: $ovex-app-background-colour;

  > .uu5-bricks-alert-bus {
    top: $ovex-navbar-height * 1.5;
  }

  .uu5-bricks-page-content-body {
    height: 100%;
  }

  .uu5-bricks-page-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;

    box-shadow: 0 6px 10px 0 rgb(0 0 0 / 14%), 0 1px 18px 0 rgb(0 0 0 / 12%), 0 1px 5px 1px rgb(0 0 0 / 30%); // uu shadow when "page-bottom" fixed
  }


}
