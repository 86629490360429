
.ovex-dealer-config-specification-table {

  .ag-react-container{
    width: 100%;
  }

  .center-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .general-setting {
    font-size: 22px;
    margin-bottom: 4px;
  }

  .specification-header-cell {
    justify-content: center;

    .ag-header-cell-label {
      justify-content: center;
    }

    &-new-model-group{
      color: orange;
    }
  }

  .specification-label-cell {
    .ag-react-container {
      height: 100%;

      .ovex-dealer-renderer {
        height: inherit;
      }
    }
  }
  .dealer-name-not-active {
    color: gray
  }
}