@use '../../../../../assets/sass/variables.scss' as *;
@use '../ButtonClassNames' as *;

$uu-classname-button: 'uu5-bricks-button';

.#{$ovex-classname-button} {

  &.#{$uu-classname-button} {
    // .ovex-button.uu5-bricks-button

    &.#{$ovex-classname-button}--primary {
      // {parent}.ovex-button--primary
      background-color: $skoda-primary-colour-skoda-green;
    }

    &.#{$ovex-classname-button}--secondary {
      // {parent}.ovex-button--secondary
      background-color: $ovex-app-background-colour;

      &:focus, &:hover {
        background-color: ovex-darken($ovex-base-colour, 6.6666%); // #EEEEEE
        border-color: ovex-darken($ovex-base-colour, 54.1176%); // #757575
      }
      &:active, &:active:focus, &:active:hover  {
        background-color: ovex-darken($ovex-base-colour, 12.1569%); // #E0E0E0
        border-color: ovex-darken($ovex-base-colour, 87.0588%); // #212121
      }
    }

    &.#{$ovex-classname-button}--floating {
      // {parent}.ovex-button--floating
    }
  }

  svg:first-child {
    margin-right: 4px;
  }
}