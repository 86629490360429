@use '../../../../../assets/sass/variables.scss' as *;

.ovex-pg-PriceGuaranteeItemTable {

  &-column {
    // .ovex-pg-PriceGuaranteeItemTable-column

    &--previous {
      // .ovex-pg-PriceGuaranteeItemTable-column--previous
      background-color: $ovex-skoda-green-lighten-64;
      --ag-header-cell-hover-background-color: #{$ovex-skoda-green-lighten-64};
    }
    &--current {
      // .ovex-pg-PriceGuaranteeItemTable-column--current
      background-color: $ovex-skoda-green-lighten-40;
      --ag-header-cell-hover-background-color: #{$ovex-skoda-green-lighten-40};
    }
  }

  .ag-cell {
    font-family: monospace;
  }

  &-row {
    &--new.ag-row {
      // .ovex-pg-PriceGuaranteeItemTable-row--new
      color: $ovex-colour-orange;
      font-weight: bold;
    };
    &--delete.ag-row {
      // .ovex-pg-PriceGuaranteeItemTable-row--delete
      color: $ovex-colour-red;
      font-weight: bold;
    };
  }

  &-cell {
    &--code {
      // .ovex-pg-PriceGuaranteeItemTable-cell--code
      letter-spacing: 0.8px;
    }

    &--editable {
      // .ovex-pg-PriceGuaranteeItemTable-cell--editable
      background-color: $ovex-ag-grid-cell-background-editable;
      &:not(.ag-cell-focus):not(.ag-cell-range-selected) {
        border-right-color: $ovex-app-background-colour !important;
      }
    }
    &--invalid {
      // .ovex-pg-PriceGuaranteeItemTable-cell--invalid
      background-color: $ovex-ag-grid-cell-background-invalid;
    }
    &--modified {
      // .ovex-pg-PriceGuaranteeItemTable-cell--modified
      background: $radial-gradient-modified, $ovex-ag-grid-cell-background-editable;

      &.ovex-pg-PriceGuaranteeItemTable-cell--invalid {
        background: $radial-gradient-modified, $ovex-ag-grid-cell-background-invalid;
      }
    }
  }

  .ag-row-hover &-cell {
    &--editable {
      // .ovex-pg-PriceGuaranteeItemTable-cell--editable
      background-color: $ovex-ag-grid-cell-background-editable-hover;
    }
    &--invalid {
      // .ovex-pg-PriceGuaranteeItemTable-cell--invalid
      background-color: $ovex-ag-grid-cell-background-invalid-hover;
    }
  }

}
