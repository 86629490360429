@use '../../../../../../assets/sass/variables.scss' as *;

.ovex-dealer-config-production-corridor-text {
  margin-bottom: 8px;
}

.ovex-dealer-config-production-corridor-table {
  margin-bottom: 8px;

  &-modified{
    background: $radial-gradient-modified;
  }

  .center-cell {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pc-header-cell {
    justify-content: center;
    .ag-header-cell-label {
      justify-content: center;
    }
  }
}