
// Primary Colours
$skoda-primary-colour-skoda-green: #4ba82e;
$skoda-primary-colour-white: #ffffff;
$skoda-primary-colour-black: #000000;
$skoda-primary-colour-skoda-chrome-grey: #a7aeb4;

// Secondary Colours
// Blue Colour Scheme
$skoda-secondary-colour-scheme-blue-a: #0090d7;
$skoda-secondary-colour-scheme-blue-b: #7ec9f1;
$skoda-secondary-colour-scheme-blue-c: #004f76;
$skoda-secondary-colour-scheme-blue-d: #777777;
$skoda-secondary-colour-scheme-blue-e: #4a4a4a;
$skoda-secondary-colour-scheme-blue-f: #b8b8b8;
// Red Colour Scheme
$skoda-secondary-colour-scheme-red-a: #e62336;
$skoda-secondary-colour-scheme-red-b: #b00835;
$skoda-secondary-colour-scheme-red-c: #ea5167;
$skoda-secondary-colour-scheme-red-d: #e5d1a2;
$skoda-secondary-colour-scheme-red-e: #878787;
$skoda-secondary-colour-scheme-red-f: #cecece;
// Yellow Colour Scheme
$skoda-secondary-colour-scheme-yellow-a: #d3da44;
$skoda-secondary-colour-scheme-yellow-b: #a2c617;
$skoda-secondary-colour-scheme-yellow-c: #ffdf43;
$skoda-secondary-colour-scheme-yellow-d: #fff374;
$skoda-secondary-colour-scheme-yellow-e: #b2b2b2;
$skoda-secondary-colour-scheme-yellow-f: #9d9d9d;
// Turquoise Colour Scheme
$skoda-secondary-colour-scheme-turquoise-a: #15af97;
$skoda-secondary-colour-scheme-turquoise-b: #008a83;
$skoda-secondary-colour-scheme-turquoise-c: #76b4af;
$skoda-secondary-colour-scheme-turquoise-d: #74a3a1;
$skoda-secondary-colour-scheme-turquoise-e: #b2b2b2;
$skoda-secondary-colour-scheme-turquoise-f: #707070;
