@use '../../../../../assets/sass/variables.scss' as *;

.ovex-pvt-SwapCommissionPvtTable {

  &-row--pinned {
    // .ovex-pvt-SwapCommissionPvtTable-row--pinned
    background: $ovex-colour-lightblue !important;
    border-bottom: solid 2px $ovex-colour-gray-75 !important;
    font-weight: 600;

    & > .ag-cell {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

}
