@use '../../../../../assets/sass/variables.scss' as *;

@use '../DealerContracts.variables' as *;



.ovex-dealer-contracts-table {

  .empty-cell > * {
    display: none;
    pointer-events: none; /* Disable clicks */
    cursor: default; /* Optionally change the cursor */
  }

  .ag-pinned-left-header {
    border-right-width: 0;
  }

  & &-column { // .ovex-dealer-contracts-table-column
    &--dealer-number {  // .ovex-dealer-contracts-table-column--dealer-number
      .ag-cell-wrapper {
        margin-left: 0 !important;
      }
    }

    &--roc { // .ovex-dealer-contracts-table-column--roc
      background-color: $ovex-dealer-contracts-roc-background-color;
      text-align: right;
      padding-right: 16px;
    }

    &--rcp { // .ovex-dealer-contracts-table-column--rcp
      background-color: $ovex-dealer-contracts-rcp-background-color;
      text-align: right;
      padding-right: 16px;
    }
  }
}

.ovex-dealer-contracts-table-detail {

  .ovex-ag-grid {
    margin-top: $ovex-horizontal-space;
    margin-left: 560px;
  }

}
