
.ovex-environment-bar {
  position: absolute;
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;

  &-content {
    width: 40%;
    font-size: 10px;
    text-align: center;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
  }
}