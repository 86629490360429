@use '../../../../assets/sass/variables.scss' as *;

$class-page: '.ovex-page';

#{$class-page} {

  .uu5-bricks-header {
    margin-top: 0;
  }
}

.uu5-bricks-page .uu5-bricks-page-content #{$class-page} {
  max-width: 100%;
  margin-bottom: $ovex-footer-height;
  padding: 24px 48px;
}
