
.ovex-UUPageAlertBus {

  &--block {
    z-index: 80; // UU5.Bricks.Alert has z-index 90
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000003d;
  }
}