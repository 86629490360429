
.ovex-pg-PriceGuaranteeRefreshStorageData {
  display: flex;
  align-items: center;
}

.ovex-pg-PriceGuaranteeRefreshStorageData-paragraph {
  width: 70%;
}

.ovex-pg-PriceGuaranteeRefreshStorageData-button {
  width: 35%;
}