
.ovex-pg-PriceGuaranteeFormHeader {

  .uu5-bricks-row {

    .uu5-bricks-column {
      .uu5-forms-input:last-child {
        margin-bottom: 0;
      }

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  .ovex-forms-checkbox {
    .uu5-forms-right-wrapper {
      padding-left: 0;
    }
  }

}
