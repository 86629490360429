@use '../../../../../assets/sass/variables.scss' as *;

.ovex-forms-tagSelect {

  .uu5-forms-label {
    font-size: $ovex-form-input-label-font-size;
  }

  .uu5-forms-message {
    font-size: $ovex-form-input-message-font-size;
    text-align: $ovex-form-input-message-text-align;
  }

  &.color-schema-green {

    .uu5-forms-items-input {
      color: $ovex-form-input-color;

      &:not(.uu5-forms-items-input-open) .uu5-forms-text-input {
        width: 0;
      }

      &.uu5-forms-items-input-open .uu5-forms-text-input {
        width: 32px;
      }
    }

    &:not(.uu5-forms-input-read-only) .uu5-forms-items-input {
      // editable input
      background: $ovex-form-input-background-color-editable !important;
    }
    &.uu5-forms-input-read-only .uu5-forms-items-input {
      // read only input
      background: $ovex-form-input-background-color-readonly !important;
    }

    .uu5-common-bg-style-underline:not(.uu5-forms-input-error) {
      border-color: $ovex-form-input-underline-color !important;
    }

    .uu5-common-focus:focus {
      background-color: transparent !important;
    }
  }

  .uu5-common-bg-style-underline:focus {
    border-bottom-width: $ovex-form-input-underline-width-active;
  }

}
