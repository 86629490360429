@use '../../../../../assets/sass/variables.scss' as *;

.ovex-forms-select {

  &--value-changed { // .ovex-forms-select--value-changed
    .uu5-forms-input-wrapper:after {
      content: '';
      background: $radial-gradient-modified;
      height: 8px;
      width: 10px;
      position: absolute;
      top: 1px;
      right: 1px;
    }
  }

  & &-clearable-icon.uu5-bricks-icon { // .ovex-forms-select-clearable-icon
    right: 32px;
    padding: 6px 0;
    background-color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
  }

  .uu5-forms-label {
    font-size: $ovex-form-input-label-font-size;
  }

  .uu5-forms-message {
    font-size: $ovex-form-input-message-font-size;
    text-align: $ovex-form-input-message-text-align;
  }

  &.color-schema-green {

    .uu5-forms-items-input {
      color: $ovex-form-input-color;
    }

    &:not(.uu5-forms-input-read-only) .uu5-forms-items-input {
      // editable input
      background: $ovex-form-input-background-color-editable !important;
    }
    &.uu5-forms-input-read-only .uu5-forms-items-input {
      // read only input
      background: $ovex-form-input-background-color-readonly !important;
    }

    .uu5-common-bg-style-underline:not(.uu5-forms-input-error) {
      border-color: $ovex-form-input-underline-color !important;

      .uu5-forms-input-form-item-value {
        color: $ovex-form-input-color !important;
      }
    }
  }

  .uu5-common-bg-style-underline {
    &:focus,
    &.uu5-forms-items-input-open {
      border-bottom-width: $ovex-form-input-underline-width-active;
    }
  }

}
