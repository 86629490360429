@use '../../../../../../assets/sass/variables.scss' as *;

.ovex-form-QuarterPeriodCheckbox {
  & .ovex-forms-checkbox {
    margin: 0;
  }

  .chck-label {
    font-size: $ovex-form-input-label-font-size;
    text-align: left;
  }

  .toolTip-color {
    color: #43A047;
  }

  .uu5-forms-label {
    text-align: left;
  }

  .ovex-pvt-balanceLabel {
    padding-top: 0;
    width: 50%;
    display: flex;
  }

  .ovex-pvt-balance-checkboxes {
    width: 50%;

    .checkBoxColumn {
      padding-left: 0;

      .center-items {
        display: flex;
        align-items: center;
      }

      .space-around {
        justify-content: space-around;
      }
      .checkBoxColumn .uu5-forms-right-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
  }

  .ovex-pvt-balance {
    display: flex;
  }

}
