@use '../../../../../assets/sass/variables.scss' as *;
@use '../ButtonClassNames' as *;

$uu-classname-dropdown: 'uu5-bricks-dropdown';

.#{$ovex-classname-dropdown} {

  .uu5-bricks-dropdown-menu-list {
    background-color: $ovex-app-background-colour;
    border-color: ovex-darken($ovex-app-background-colour, 25.8824%);

    .uu5-bricks-dropdown-item-link:not(.uu5-common-disabled):hover {
      background-color: $skoda-primary-colour-skoda-green;
      color: $skoda-primary-colour-white;
    }
  }

  &.#{$uu-classname-dropdown} {
    // .ovex-dropdown.uu5-bricks-dropdown

    &.#{$ovex-classname-dropdown}--primary {
      // {parent}.ovex-dropdown--primary
      .uu5-bricks-button {
        background-color: $skoda-primary-colour-skoda-green;
      }
    }

    &.#{$ovex-classname-dropdown}--secondary {
      // {parent}.ovex-dropdown--secondary
      .uu5-bricks-button {
        background-color: $ovex-app-background-colour;

        &:focus, &:hover {
          background-color: ovex-darken($ovex-base-colour, 6.6666%); // #EEEEEE
          border-color: ovex-darken($ovex-base-colour, 54.1176%); // #757575
        }
        &:active, &:active:focus, &:active:hover  {
          background-color: ovex-darken($ovex-base-colour, 12.1569%); // #E0E0E0
          border-color: ovex-darken($ovex-base-colour, 87.0588%); // #212121
        }
      }
    }

    &.#{$ovex-classname-dropdown}--floating {
      // {parent}.ovex-dropdown--floating
      .uu5-bricks-button {
      }
    }
  }


}