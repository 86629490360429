
.ovex-form-Periods {

  .uu5-forms-input-wrapper {
    .ovex-form-Period {
      margin-top: 8px;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

  }

}