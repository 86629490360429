@use '../../../../../assets/sass/variables.scss' as *;


.ovex-at-ContractPreview {
  border: $ovex-colour-lightgray dashed 1px;
  padding: 32px;
  width: 800px;
  margin-right: auto;
  white-space: pre-wrap;
  font-size: 14px;

  > div:not(:last-child) {
    margin-bottom: 24px;
  }

  &-contractHeader { // .ovex-at-ContractPreview-contractHeader
    text-align: center;

    &-title, // .ovex-at-ContractPreview-contractHeader-title
    &-subject { // .ovex-at-ContractPreview-contractHeader-subject
      font-weight: bold;
      font-size: 18px;
    }

    &-validFromText { // .ovex-at-ContractPreview-contractHeader-validFromText
      margin-top: 16px;
      font-size: 12px;
    }
  }

  &-suppDeal { // .ovex-at-ContractPreview-suppDeal
    display: flex;

    > .ovex-at-ContractPreview-company {
      width: 50%;
    }
  }

  &-company { // .ovex-at-ContractPreview-company

    &-header { // .ovex-at-ContractPreview-company-header
      font-weight: bold;
    }

    &-address { // .ovex-at-ContractPreview-company-address
      margin-top: 8px;
      margin-left: 16px;
      font-size: 12px;
    }

    &-number { // .ovex-at-ContractPreview-company-number
      margin-left: 16px;
      font-size: 12px;
    }
  }

  &-textBlock { // .ovex-at-ContractPreview-textBlock

    &-header { // .ovex-at-ContractPreview-textBlock-header
      font-weight: bold;
    }

    &-text { // .ovex-at-ContractPreview-textBlock-text
      margin-top: 8px;
      margin-left: 16px;
      font-size: 12px;
    }

  }

  &-tableBlock { // .ovex-at-ContractPreview-tableBlock

    &-header { // .ovex-at-ContractPreview-tableBlock-header
      font-weight: bold;
    }

    &-table { // .ovex-at-ContractPreview-tableBlock-table
      white-space: normal; // fix ag-grid - *pre-wrap* from parent ident some rows
      margin-top: 8px;

      .ovex-ag-grid .ag-row-last {
        border-bottom-style: none;
      }
    }

  }

}
