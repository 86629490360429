
.ovex-dealer-config-specification-button-bar {
  margin-bottom: 8px;

  .general-setting {
    font-size: 22px;
    margin-bottom: 4px;
  }

  .effectiveness-label {
    display: flex;
    align-items: center;

    &-text{
      margin-left: 8px;
      padding-top: 1px;
      font-size: 13px;
    }
  }

}