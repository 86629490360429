
.ovex-agg-ModelGroupRowInnerRenderer {
  height: 20px;
  display: flex;
  align-items: center;

  &-actions {
    // .ovex-agg-ModelGroupRowInnerRenderer-actions
    width: 132px;
  }
  &-label {
    // .ovex-agg-ModelGroupRowInnerRenderer-label
  }
}
