
.ovex-ag-CustomHeaderLabel {

  &-icon {
    // .ovex-ag-CustomHeaderLabel-icon
    flex-shrink: 0;

    + .ag-header-cell-text {
      margin-left: 4px;
    }
  }

}
