@use '../../../../../../assets/sass/variables.scss' as *;

.AtWeekCellRenderer {
  display: flex;
  flex-direction: column;

  &-total { // .AtWeekCellRenderer-total
    &--hasValue { // .AtWeekCellRenderer-total--hasValue
      background-color: $ovex-skoda-green-lighten-08;
    }
  }

  &-value { // .AtWeekCellRenderer-value
    text-align: center;
  }
}
