@use '../../../../../assets/sass/variables.scss' as *;

.ovex-pvt-SwapCommissionPvtIcon {
  text-align: center;

  //ovex-pvt-SwapCommissionPvtIcon-left
  &-right {
    color: $ovex-colour-red;
  }

  //ovex-pvt-SwapCommissionPvtIcon-right
  &-left {
    color: $ovex-colour-green;
  }
}
