@use '../../../../../../assets/sass/variables.scss' as *;

@use '../ValidationResultColors' as *;

.ovex-pg-ValidationResultHeaderTable {
  max-width: 1000px;

  &-column {
    // .ovex-pg-ValidationResultHeaderTable-column

    &--header {
      // .ovex-pg-ValidationResultHeaderTable-column--header
      background-color: ovex-transparentize(ovex-darken($ovex-app-background-colour, 18%), 56%);
    }
  }

  &-validationResult {
    // .ovex-pg-ValidationResultHeaderTable-validationResult

    &--ok {
      // .ovex-pg-ValidationResultHeaderTable-validationResult--ok
      background-color: $ovex-pg-validation-result-ok;
    }
    &--ok-sent {
      // .ovex-pg-ValidationResultHeaderTable-validationResult--ok-sent
      background-color: $ovex-pg-validation-result-sent;
    }
    &--repairable {
      // .ovex-pg-ValidationResultHeaderTable-validationResult--repairable
      background-color: $ovex-pg-validation-result-repairable;
    }
    &--notRepairable {
      // .ovex-pg-ValidationResultHeaderTable-validationResult--notRepairable
      background-color: $ovex-pg-validation-result-not-repairable;
    }
  }

}
