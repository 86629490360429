@use '../../../../../assets/sass/variables.scss' as *;

$ovex-default-ag-header-column-separator-color: rgba(182, 182, 182, 0.5);
$restrictions-table-colour-separator-quarter: ovex-darken($ovex-default-ag-header-column-separator-color, 80%);
$restrictions-table-colour-separator-month: ovex-darken($ovex-default-ag-header-column-separator-color, 8%);
$restrictions-table-not-separator-css-selector: ':not(.ag-cell-range-selected.ag-cell-range-right):not(.ag-cell-range-selected.ag-cell-range-single-cell)';

.RestrictionsTable {

  .ovex-RestrictionsButtonBar {
    margin-bottom: $ovex-horizontal-space;
  }

  &-tabs {
    .uu5-bricks-tabs-list-tabs {
      margin-bottom: 0;

      > li > .uu5-bricks-button {
        margin-top: 0;
      }
    }
  }

  &-column { // .RestrictionsTable-column
    &-definition { // .RestrictionsTable-column-definition

      &--addedRestriction {  // .RestrictionsTable-column-definition--addedRestriction
        font-weight: bold;
        color: $ovex-colour-green;
      }

      &--deletedInFuture {  // .RestrictionsTable-column-definition--deletedInFuture
        font-weight: bold;
        color: $ovex-colour-red;
      }

      &--newRestriction {  // .RestrictionsTable-column-definition--newRestriction
        font-weight: bold;
        color: $ovex-colour-orange;
      }
    }

    &-lastWeekOfMonth#{$restrictions-table-not-separator-css-selector} {  // .RestrictionsTable-column-lastWeekOfMonth
      border-right-color: $restrictions-table-colour-separator-month;
    }
    &-lastWeekOfQuarter#{$restrictions-table-not-separator-css-selector} {  // .RestrictionsTable-column-lastWeekOfQuarter
      border-right-color: $restrictions-table-colour-separator-quarter;
    }

    &-headerSeparator { // .RestrictionsTable-column-headerSeparator
      &-month { // .RestrictionsTable-column-headerSeparator-month
        &.ag-header-cell:not(:last-child)::after {
          background-color: $restrictions-table-colour-separator-month;
        }
        &.ag-header-group-cell:not(:last-child)::after {
          background-color: $restrictions-table-colour-separator-month;
        }
      }
      &-quarter { // .RestrictionsTable-column-headerSeparator-quarter
        &.ag-header-cell:not(:last-child)::after {
          background-color: $restrictions-table-colour-separator-quarter;
        }
        &.ag-header-group-cell:not(:last-child)::after {
          background-color: $restrictions-table-colour-separator-quarter;
        }
      }
    }
  }

  & &-row {  // .RestrictionsTable-row

    &--quota {  // .RestrictionsTable-row--quota
      font-weight: bold;
    }

    &--restriction {  // .RestrictionsTable-row--restriction
      .RestrictionsTable-column--modelGroup {
        padding-left: 24px;
      }
    }

  }
}
