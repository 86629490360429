@use 'ag-grid-enterprise/styles' as ag;
@use '../../../../../assets/sass/variables.scss' as *;

@use 'ag-grid-enterprise/styles/ag-grid';

@include ag.grid-styles((
        theme: balham,
        border-radius: $ovex-border-radius,
  // selecting, editing, ...
        balham-active-color: $skoda-primary-colour-skoda-green,
        input-focus-border-color: $skoda-primary-colour-skoda-green,
  // backgrounds
        background-color: $ovex-app-background-colour, // white
        header-background-color: ovex-darken($ovex-app-background-colour, 8%), // #f5f7f7 (f6f6f6) - #ececec
        control-panel-background-color: $ovex-app-background-colour, // #f5f7f7
        odd-row-background-color: $ovex-app-background-colour, // #fcfdfe (#fcfcfc) - white
        row-hover-color: transparent,
  // borders
        border-color: ovex-darken($ovex-app-background-colour, 24%), // #bdc3c7 (c1c1c1)
  //row-border-color: ovex-darken($ovex-app-background-colour, 4%),
        cell-horizontal-border: solid ovex-lighten($ovex-app-background-colour, 64%),

        row-border-color: #d0d0d0
));

.ag-theme-balham .ag-menu-list .ag-menu-option-active {
  background-color: ovex-darken($ovex-app-background-colour, $ovex-ag-grid-hover-darken);
}

.ovex-ag-grid {

  .ag-header-span-height:not(.ag-header-group-cell-no-group) {
    top: unset !important;
    height: unset !important;
    border-top: 1px solid #b6b6b6 !important;
    border-top-color: var(--ag-border-color, #b6b6b6) !important;
  }
  & {
    > div {
      position: relative;
    }
    .ag-root-wrapper {
      position: initial;
      border: none;
    }
  }

  &--highlightLeafOddEvenRows {
    // .ovex-ag-grid--highlightLeafOddEvenRows

    .ovex-ag-leaf-row {

      &-odd {
        // .ovex-ag-leaf-row-odd
        $local-bg-color: ovex-lighten($ovex-app-background-colour, 24%);
        background-color: $local-bg-color; // 1.1765%
        &.ag-row-hover {
          background-color: ovex-darken($local-bg-color, $ovex-ag-grid-hover-darken);
        }
      }

      &-even {
        // .ovex-ag-leaf-row-even
        $local-bg-color: ovex-darken($ovex-app-background-colour, 3%);
        background-color: $local-bg-color; // 1.1765%
        &.ag-row-hover {
          background-color: ovex-darken($local-bg-color, $ovex-ag-grid-hover-darken);
        }
      }
    }
  }

  &--th-wrap { // .ovex-ag-grid--th-wrap
    .ag-header-cell-text {
      white-space: pre;
    }
  }

  &--th-center { // .ovex-ag-grid--th-center
    .ag-header-cell-label,
    .ag-header-group-cell-label {
      justify-content: center;
      flex: auto;

      .ag-header-cell-text,
      .ag-header-group-text {
        text-align: center;
        flex: auto;
      }
    }
  }

  &--td-right.ag-cell:not(.ag-cell-inline-editing) { // .ovex-ag-grid--td-right
    text-align: right;
    padding-right: 28px;
  }

  &--td-center.ag-cell:not(.ag-cell-inline-editing) { // .ovex-ag-grid--td-center
    text-align: center;
  }

  &--renderer-fill-cell.ag-cell { // .ovex-ag-grid--renderer-fill-cell
    padding: 0;
  }

  .ag-row-hover:not(.ag-row-group) {
    background-color: ovex-darken($ovex-app-background-colour, $ovex-ag-grid-hover-darken);
  }


  .ag-row-group {
    &.ag-row-level-0 {
      $local-bg-color: ovex-darken($ovex-app-background-colour, 16%);
      background-color: $local-bg-color;
      &.ag-row-hover {
        background-color: ovex-darken($local-bg-color, $ovex-ag-grid-hover-darken);
      }
    }
    &.ag-row-level-1 {
      $local-bg-color: ovex-darken($ovex-app-background-colour, 12%);
      background-color: $local-bg-color;
      &.ag-row-hover {
        background-color: ovex-darken($local-bg-color, $ovex-ag-grid-hover-darken);
      }
    }
    &.ag-row-level-2 {
      $local-bg-color: ovex-darken($ovex-app-background-colour, 8%);
      background-color: $local-bg-color;
      &.ag-row-hover {
        background-color: ovex-darken($local-bg-color, $ovex-ag-grid-hover-darken);
      }
    }
    &.ag-row-level-3 {
      $local-bg-color: ovex-darken($ovex-app-background-colour, 4%);
      background-color: $local-bg-color;
      &.ag-row-hover {
        background-color: ovex-darken($local-bg-color, $ovex-ag-grid-hover-darken);
      }
    }
  }

  .ag-tabs .ag-filter {

    .ag-filter-apply-panel-button {
      border-radius: 16px;
      border: $ovex-colour-gray-75 $ovex-border-style $ovex-border-width;
      padding: 2px 10px;
    }

    .ag-multi-filter .ag-filter-separator {
      border-top-width: 2px;
    }

  }

  .ag-popup-editor {
    .uu5-forms-input {
      margin: 0;
    }
  }

}
