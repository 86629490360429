
.ovex-IconButton {
  background: transparent;
  border: none;
  border-radius: 16px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
    background: rgba(0, 0, 0, 0.10);
  }
}
