@use '../../../../assets/sass/variables.scss' as *;

.AvailableCapacity {
  display: flex;
  flex-direction: column;

  .ovex-AvailableCapacityButtonBar {
    margin-bottom: $ovex-horizontal-space;
  }

  .ovex-AvailableCapacityFilter {
    margin-bottom: $ovex-horizontal-space;
  }

}