@use '../../../../../assets/sass/variables.scss' as *;

.ovex-forms-list-selector {
  display: flex;

  &-table { // .ovex-forms-list-selector-table
    &-available-options { // .ovex-forms-list-selector-table-available-options
    }
    &-selected-options { // .ovex-forms-list-selector-table-selected-options
    }
  }

  &-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;

    .ovex-Button {
      margin: 4px;
    }
  }

}