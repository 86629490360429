@use '../../../assets/sass/variables.scss' as *;

@media print {
  .ovex-app {
    background-color: initial;

    .uu5-bricks-page-top, // navbar
    .uu5-bricks-page-bottom // footer
    {
      display: none;
    }

  }
}