@use '../../../../../assets/sass/variables.scss' as *;


.ovex-DealerModelGroupModalErrorLabel {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ovex-DealerModelGroupModalColoredInfoLabel {
  padding: 20px;
  background-color: #FFF5E4;
}
.ovex-DealerModelGroupModalColoredErrorLabel {
  padding: 20px 0 0 20px;
  background-color: #FFF5E4;
}

.ovex-DealerModelGroupModalColoredNoteLabel {
  background-color: #FFF5E4;
  font-size: 10px;
  padding: 0 0 20px 20px;
}

.ovex-DealerModelGroupModalButtonBar {
  display: flex;
  justify-content: center;
  margin: 20px;
}

