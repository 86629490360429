@use '../../../../../assets/sass/variables.scss' as *;

.ovex-forms-checkboxes {

  .uu5-forms-label {
    font-size: 12px;
  }

  .uu5-forms-message {
    font-size: $ovex-form-input-message-font-size;
    text-align: $ovex-form-input-message-text-align;
    border-top-width: $ovex-form-input-underline-width-error;
  }

  .uu5-forms-input-read-only .uu5-forms-checkbox.uu5-forms-input.uu5-forms-checkbox-checked .uu5-forms-right-wrapper,
  .uu5-forms-input-read-only.uu5-forms-checkbox.uu5-forms-input.uu5-forms-checkbox-checked .uu5-forms-right-wrapper {
    background-color: transparent;
  }

  .uu5-forms-checkbox.uu5-common-bg-style-outline:not(.uu5-common-disabled):not(.uu5-forms-input-read-only).uu5-forms-checkbox-checked .uu5-forms-checkbox-button:not(.uu5-bricks-switch) .uu5-bricks-icon {
    color: $skoda-primary-colour-skoda-green;
  }

  .uu5-forms-checkbox.uu5-forms-input-m.uu5-forms-checkbox-type-switch {
    .uu5-bricks-switch {
      height: 20px;
      width: 30px;
      min-width: 30px;

      .uu5-bricks-switch-icon {
        font-size: 14px;
      }
    }
  }

}
