@use '../../../../../assets/sass/variables.scss' as *;

$ovex-default-ag-header-column-separator-color: rgba(182, 182, 182, 0.5);
$restrictions-table-colour-separator-quarter: ovex-darken($ovex-default-ag-header-column-separator-color, 80%);
$restrictions-table-colour-separator-month: ovex-darken($ovex-default-ag-header-column-separator-color, 8%);
$restrictions-table-not-separator-css-selector: ':not(.ag-cell-range-selected.ag-cell-range-right):not(.ag-cell-range-selected.ag-cell-range-single-cell)';

.ovex-AvailableCapacityTable {

  &-column { // .ovex-AvailableCapacityTable-column
    &-lastWeekOfMonth#{$restrictions-table-not-separator-css-selector} { // .ovex-AvailableCapacityTable-column-lastWeekOfMonth
      border-right-color: $restrictions-table-colour-separator-month;
    }

    &-lastWeekOfQuarter#{$restrictions-table-not-separator-css-selector} { // .ovex-AvailableCapacityTable-column-lastWeekOfQuarter
      border-right-color: $restrictions-table-colour-separator-quarter;
    }

    &-headerSeparator { // .ovex-AvailableCapacityTable-column-headerSeparator
      &-quarter {
        &.ag-header-cell:not(:last-child)::after {
          background-color: $restrictions-table-colour-separator-quarter;
        }

        &.ag-header-group-cell:not(:last-child)::after {
          background-color: $restrictions-table-colour-separator-quarter;
        }
      }

      &-month { // .ovex-AvailableCapacityTable-column-headerSeparator-month
        &.ag-header-cell:not(:last-child)::after {
          background-color: $restrictions-table-colour-separator-month;
        }

        &.ag-header-group-cell:not(:last-child)::after {
          background-color: $restrictions-table-colour-separator-month;
        }
      }

      //&-quarter { // .ovex-AvailableCapacityTable-column-headerSeparator-quarter
      //  &.ag-header-cell:not(:last-child)::after {
      //    background-color: $restrictions-table-colour-separator-quarter;
      //  }
      //
      //  &.ag-header-group-cell:not(:last-child)::after {
      //    background-color: $restrictions-table-colour-separator-quarter;
      //  }
      //}
    }
  }


  .cell-span {
    &:not(.ag-cell-range-single-cell):not(.ag-cell-range-bottom) { //.ovex-AvailableCapacityTable-cell-span
      border-bottom: solid 1px #d0d0d0 !important;
    }

    &-center { //.ovex-AvailableCapacityTable-cell-span-center
      display: flex;
      justify-content: center;
    }
  }

  .ag-pinned-left-cols-container .ag-row {
    border: none;
  }

  .restriction {
    display: flex;
    align-items: center;
  }

  .ag-row-last {
    border-bottom: solid 1px black !important
  }

  .ag-row-first {
    border-top: solid 1px black !important
  }

  .row-black-line-top {
    border-top: solid 1px black !important
  }

  .AvailableCapacity-column--modelGroup {
    padding-left: 24px;
    font-weight: bold;
  }


}





