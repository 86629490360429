
.ovex-pg-BooleanIconCellRenderer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-agContainer {
    // .ovex-pg-BooleanIconCellRenderer-agContainer
    height: 100%;
  }
}
