@use '../../../../assets/sass/variables.scss' as *;

.ovex-Tabs {

  .uu5-bricks-tabs-list-tabs {
    .uu5-bricks-tabs-list-active > .uu5-bricks-button-transparent {
      border-color: $skoda-primary-colour-skoda-green;

      &:active, &:focus, &:hover {
        border-color: ovex-darken($skoda-primary-colour-skoda-green, 8%);
      }
    }
  }
}
