@use '../../../../../../assets/sass/variables.scss' as *;
.ovex-NegotiationModelGroupListForm {

  & &-productionCapacity {
    // .ovex-NegotiationModelGroupListForm-productionCapacity
    &.ovex-ag-grid--td-right.ag-cell:not(.ag-cell-inline-editing) {
      padding-left: 7px;
      padding-right: 7px;
    }

    &-total:not(.ovex-NegotiationModelGroupListForm-cell--invalid) {
      // .ovex-NegotiationModelGroupListForm-productionCapacity-total
      background-color: $ovex-colour-gray-e6;
    }
  }

  &-cell {
    &--editable {
      // .ovex-NegotiationModelGroupListForm-cell--editable
      background-color: $ovex-ag-grid-cell-background-editable;
      &:not(.ag-cell-focus):not(.ag-cell-range-selected) {
        border-right-color: $ovex-app-background-colour !important;
      }
    }
    &--invalid {
      // .ovex-NegotiationModelGroupListForm-cell--invalid
      background-color: $ovex-ag-grid-cell-background-invalid;
    }
    &--modified {
      // .ovex-NegotiationModelGroupListForm-cell--modified
      background: $radial-gradient-modified, $ovex-ag-grid-cell-background-editable;

      &.ovex-NegotiationModelGroupListForm-cell--invalid {
        background: $radial-gradient-modified, $ovex-ag-grid-cell-background-invalid;
      }
    }

    &--modified-not-editable {
      // .ovex-NegotiationModelGroupListForm-cell--modified-not-editable
      background: $radial-gradient-modified;
    }
  }

  .ag-row-hover &-cell {
    &--editable {
      // .ovex-NegotiationModelGroupListForm-cell--editable
      background-color: $ovex-ag-grid-cell-background-editable-hover;
    }
    &--invalid {
      // .ovex-NegotiationModelGroupListForm-cell--invalid
      background-color: $ovex-ag-grid-cell-background-invalid-hover;
    }
  }
}
