@use '../../../../../assets/sass/variables.scss' as *;

.ovex-FilterWrapper {
  border: ovex-darken($ovex-app-background-colour, 24%) solid 1px;
  border-left-width: 0;
  border-right-width: 0;
  padding: 0 12px 8px 12px;

  background-color: ovex-darken($ovex-app-background-colour, 4%);

  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  .uu5-forms-input:not(.uu5-forms-input-inner) {
    margin-top: 8px;
    margin-bottom: 0;
    margin-right: 16px;
  }

  .ovex-forms-checkbox.uu5-forms-input-m {
    .uu5-bricks-switch.uu5-bricks-button-m {
      height: 20px;
      width: 30px;
      min-width: 30px;

      .uu5-bricks-switch-icon {
        font-size: 14px;
      }
    }
  }

}
