@use '../../assets/sass/variables.scss' as *;


.ovex-navbar {
  display: flex;
  align-items: stretch;
  background-color: $ovex-app-background-colour;
  height: $ovex-navbar-height;
  padding: 0 20px;

  &-app {
    // .ovex-navbar-app
    display: flex;
    align-items: center;
    min-width: 250px;
    width: 300px;

    &-name {
      // .ovex-navbar-app-name
      margin-left: 8px;
      color: $ovex-app-name-colour;
    }
  }

  &-app-menu {
    // .ovex-navbar-app-menu
    flex-grow: 1;
  }

  &-user-menu {
    // .ovex-navbar-user-menu
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 250px;
    width: 300px;

  }
}