@use '../../../../../assets/sass/variables.scss' as *;

@use '../DealerContracts.variables' as *;

.ovex-ModelGroupAnnualTargetTable {

  & &-cell-numeric { // .ovex-ModelGroupAnnualTargetTable-cell-numeric
    background-color: $ovex-dealer-contracts-roc-background-color;
    text-align: right;
    padding-right: 60px;

    &--editable { // .ovex-ModelGroupAnnualTargetTable-cell-numeric--editable
      &:before {
        content: "\F3EB";
        position: absolute;
        left: 8px;
      }
    }

    &--modified { // .ovex-ModelGroupAnnualTargetTable-cell-numeric--modified
      background: $radial-gradient-modified;
    }

  }
}
