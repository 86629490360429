@use '../../../../../../assets/sass/variables.scss' as *;

.ovex-pvt-ModelGroupProductionCorridorForm {

  .ovex-forms-checkbox {
    + .ovex-forms-checkbox {
      margin-top: 0;
    }
  }

}