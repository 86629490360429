
.ovex-YearSettingsHeader {

  .roc-coefficient {
    display: flex;
    margin-bottom: 10px;

    .uu5-bricks-column-spacing:last-child {
      padding-right: 0;
    }
    .uu5-bricks-column-spacing:first-child {
      padding-left: 0;
    }
    .uu5-forms-input {
      margin-top: 0;
      margin-bottom: auto;
    }
  }

  .coefficient-label {
    margin-top: 20px;
    font-size: 14px;
  }
}