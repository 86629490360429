@use '../../../../../assets/sass/variables.scss' as *;
.BaseTooltip {
  min-width: 30%;
  min-height: 1em;
  overflow: hidden;
  pointer-events: none;
  background-color: $ovex-colour-gray-e6;
  border: $ovex-border-width $ovex-border-style gray;
}

.BaseTooltip p {
  margin: 5px;
  white-space: normal;
}
