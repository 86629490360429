@use '../../../assets/sass/colors.scss' as *;


.ovex-DealerContractsATModelGroupViewTable {

  &-column {
    // .ovex-DealerContractsATModelGroupViewTable-column

    &-header-total {
      // .ovex-DealerContractsATModelGroupViewTable-column-header-total
      background-color: $ovex-annual-target-column-statistics-bg-color;
      --ag-header-cell-hover-background-color: #{$ovex-annual-target-column-statistics-bg-color};
      color: $ovex-annual-target-column-statistics-color;
    }

    &-header-group-odd {
      // .ovex-DealerContractsATModelGroupViewTable-column-header-group-odd
      background-color: $ovex-annual-target-column-group-odd-bg-color;
      --ag-header-cell-hover-background-color: #{$ovex-annual-target-column-group-odd-bg-color};
    }

    &-working {
      // .ovex-DealerContractsATModelGroupViewTable-column-working
      background-color: $ovex-annual-target-column-working-bg-color;
      --ag-header-cell-hover-background-color: #{$ovex-annual-target-column-working-bg-color};
    }
    &-approved {
      // .ovex-DealerContractsATModelGroupViewTable-column-approved
      background-color: $ovex-annual-target-column-approved-bg-color;
      --ag-header-cell-hover-background-color: #{$ovex-annual-target-column-approved-bg-color};
    }
    &-statistics {
      // .ovex-DealerContractsATModelGroupViewTable-column-statistics
      background-color: $ovex-annual-target-column-statistics-bg-color;
      --ag-header-cell-hover-background-color: #{$ovex-annual-target-column-statistics-bg-color};
      color: $ovex-annual-target-column-statistics-color;
    }

    &-cell.ag-cell {
      // .ovex-DealerContractsATModelGroupViewTable-column-cell
      text-align: right;
      padding-right: 32px;
    }
  }

}